export enum UrlPathsEnum {
  HOME = '/',
  EDUCATOR = '/educator',
  CREATOR_SIGN_UP = '/creator/signup',
  CREATOR_LOGIN = '/creator/login',
  CREATOR_PRICING = '/creator/pricing',
  EXPLORE = '/explore',
  CONTENT_CREATOR = '/content-creator',
  LIVE_STREAMING = '/live-streaming',
  BUSINESS_CHANNELS = '/business-channels',
  EVENTER_LINK = '/creator/pricing#one-off-events',
  BOOK_DEMO = 'https://calendly.com/vidzing-kimberley/platform-demo-with-our-founder-kimberley',
  KICK_START_KIMBERLEY_BOOK_DEMO = 'https://calendly.com/vidzing-kimberley/kickstart?month=2023-11',
  BLOG = 'https://creators.vidzing.tv/blog',
  MY_VIDZ = '/my-vidz/purchases',
  MY_VIDZ_SUBSCRIPTIONS = '/my-vidz/subscriptions',
  MY_VIDZ_VIDEO_REVIEW = '/my-vidz/video-review',
}
